import { application } from "./application"

import ToggleController from "./toggle_controller.js"
application.register("toggle", ToggleController)

import ModalController from "./modal_controller.js"
application.register("modal", ModalController) // TODO: cleanup

import CalendarController from "./calendar_controller.js"
application.register("calendar", CalendarController)

import ServiceController from "./service_controller.js"
application.register("service", ServiceController)

import OrderController from "./order_controller.js"
application.register("order", OrderController)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)
