import { Controller } from "@hotwired/stimulus"
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

export default class extends Controller {
  connect() {
    this.initializeCalendar();
    // this.initializeDraggable();
  }

  disconnect() {
    this.calendar.destroy()
    // this.draggable.destroy()
  }

  initializeCalendar() {
    let _this = this
    let locale = document.querySelector('body').getAttribute('data-locale')
    this.calendar = new Calendar(this.element, {
      timeZone: 'Europe/Helsinki',
      locale: 'fi',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      eventSources: [
        '/services.json'
      ],
      initialView: 'timeGridWeek',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      businessHours: {
        daysOfWeek: [1,2,3,4,5,6],
        startTime: '8:00',
        endTime: '18:00'
      },
      eventConstraint: 'businessHours',
      selectConstraint: 'businessHours',
      weekends: true,
      slotDuration: "1:00:00",
      editable: true,
      selectable: true,
      eventOverlap: false,
      slotEventOverlap: false,
      eventDrop: function (info) { _this.fetch(info) },
      eventReceive: function (info) { _this.fetch(info) },
      eventResize: function (info) { _this.fetch(info) },
      select: function(info) {
        console.log(info)
      },
      eventClick: function(info) {
        info.jsEvent.preventDefault();
        if (info.event.url) {
          let url = info.event.url + "/edit"

          fetch(url, {
            headers: {
              Accept: "text/vnd.turbo-stream.html",
            },
          })
          .then(r => r.text())
          .then(html=> Turbo.renderStreamMessage(html))
        }
      },
      navLinks: true,
    })
    this.calendar.render()
    this.calendar.setOption('locale', locale);
  }
  //
  // initializeDraggable() {
  //   let element = document.getElementById("external-events")
  //   this.draggable = new Draggable(element, {
  //     itemSelector: '.fc-event',
  //     eventData: function(event) {
  //       return {
  //         duration: "2:00"
  //       }
  //     }
  //   })
  // }

  fetch(info) {
    let url = info.event.url ? info.event.url : '/services'
    let method = info.event.url ? 'PUT' : 'POST'
    let csrf_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": csrf_token
      },
      body: JSON.stringify({service: { start_time: info.event.start, end_time: info.event.end }, modal: true } )
    })
    .then((response) => {
      this.calendar.refetchEvents();
    })
  }
}
