import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let form = document.getElementById("form-service");
    this.boundChangeForm = this.changeFormData.bind(this)
    form.addEventListener("submit", this.boundChangeForm, false);
  }

  disconnect() {
    document.removeEventListener("submit", this.boundChangeForm)
  }

  changeFormData(event) {
    event.preventDefault();
    let start_time = document.getElementById("service_start_time")
    let end_time = document.getElementById("service_end_time")
    let initial_date = start_time.value

    start_time.value = start_time.value + ' ' + document.getElementById("service_start_at").value
    end_time.value = initial_date + ' ' + document.getElementById("service_end_at").value

    document.getElementById("form-service").submit();
  }
}
