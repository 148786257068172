import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal"]

  close(event) {
    event.preventDefault();
    let element = document.getElementById("backdrop")

    this.modalTarget.remove();
    element.remove()
  }
}
